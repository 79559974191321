import type { Experiments, FlowEditorSDK } from '@wix/yoshi-flow-editor';

import { Experiment } from '../../types';

export const refreshApp = (
  editorSDK: FlowEditorSDK,
  experiments: Experiments,
  source: string,
) => {
  return experiments.enabled(Experiment.EnableOOIInEditor)
    ? editorSDK.document.application.livePreview.refresh('', {
        source,
        shouldFetchData: true,
      })
    : editorSDK.tpa.app.refreshApp('');
};
